<template>
    <div class="wrapper">
    <!-- header -->
    <!-- 大網展開menu: header-menu-sec 移除hidden -->
    <!-- 小網展開menu: wrapper 加class="header-nav-open" -->
    <header>
      <div class="pagesize">
        <div class="header-area">
          <h1><router-link to="/"><span class="zh">士林區區域電能管理系統</span><br /><span class="eng">SHILIN Community Energy Management
                System </span></router-link></h1>
          <p class="header-time">更新時間：{{updateTime}}</p>
          <a href="#" class="header-nav-btn"></a>
            <div class="header-nav">
                <a href="./login" class="btn-login" @click="LOGIN_OUT">登出</a>
                <ul class="header-menu-first">
                  <li><router-link to="/">能源地圖</router-link></li>
                  <li><router-link to="/electricity">發用電資訊</router-link></li>
                  <li><a href="#"  class="folder on" >需量反應</a>
                    <ul class="header-menu-sec">
                      <li><router-link to="./bidCalc">投標試算</router-link></li>
                      <li><router-link to="./bidRecord">得標及抑低分配發布</router-link></li>
                      <li><router-link to="./realTime">即時執行情形</router-link></li>
                      <li><router-link to="./history">歷史紀錄</router-link></li>
                      <li><router-link to="./group">群組成員資料</router-link></li>
                      <li><router-link to="./download">下載專區</router-link></li>
                    </ul>
                  </li>
                </ul>
            </div>
        </div>
      </div>
    </header>
    <!-- container -->
    <div class="container">
      <div class="pagesize">
        <ul class="breadcrumbs">
          <li><a href="#">需量反應</a></li>
          <li><span>下載專區</span></li>
        </ul>
        <div class="area bg-white">
          <div class="padding03">
            <loading :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="fullPage"></loading>
            <h2 class="boxtitle margin02">下載專區</h2>
            <div class="datahight">
                高壓用戶用電資料
                <date-picker style="width:auto; margin:5px" placeholder="起始日期" v-model="beginDate" valueType="format"></date-picker>
                ~
                <date-picker style="width:auto; margin:5px" placeholder="結束日期" v-model="endDate" valueType="format"  :disabled-date='disabledDate'></date-picker>
                <a class="btn-small bg-kahki" style="border-radius: 13px; font-size:15px" @click="getMeterMin('high')">下載</a>
            </div>
            <div class="datahight">
                低壓用戶用電資料
                <date-picker style="width:auto; margin:5px" placeholder="起始日期" v-model="beginDate" valueType="format"></date-picker>
                ~
                <date-picker style="width:auto; margin:5px" placeholder="結束日期" v-model="endDate" valueType="format"  :disabled-date='disabledDate'></date-picker>
                <a class="btn-small bg-kahki" style="border-radius: 13px; font-size:15px" @click="getMeterMin('low')">下載</a>
            </div>
            <div class="datahight">
                發電資料
                 <date-picker style="width:auto; margin:5px" placeholder="起始日期" v-model="beginDate" valueType="format"></date-picker>
                ~
                <date-picker style="width:auto; margin:5px" placeholder="結束日期" v-model="endDate" valueType="format"  :disabled-date='disabledDate'></date-picker>
                <a class="btn-small bg-kahki" style="border-radius: 13px; font-size:15px" @click="getMeterHour">下載</a>
            </div>
            <div class="datahight">
                節電資料(月)
                 <date-picker style="width:auto; margin:5px" placeholder="起始日期" v-model="yearDate" valueType="format" type="year"></date-picker>
                <a class="btn-small bg-kahki" style="border-radius: 13px; font-size:15px" @click="getEsaving">下載</a>
            </div>
            <div class="datahight">
                儲能電池
                <date-picker style="width:auto; margin:5px" placeholder="起始日期" v-model="beginDate" valueType="format"></date-picker>
                <a class="btn-small bg-kahki" style="border-radius: 13px; font-size:15px" @click="getEssData">下載</a>
            </div>
            <div class="datahight">
                需量反應
                <a class="btn-small bg-kahki" style="border-radius: 13px; font-size:15px" @click="getReactHistory">下載</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  footer -->
    <footer>
      <div class="pagesize">
        <div class="footer-info">
          <div class="info-box info-main">
            <div class="logo"></div>
            <div class="copyright">
              <p><a href="#">政府網站資料開放宣告</a>&nbsp;|&nbsp;<a href="#">隱私權及網站安全政策</a></p>
              <p>Copyright © All Rights Reserved</p>
            </div>
          </div>
          <div class="info-box info-sub">
            <p>執行單位：(02)2586-5000 # 325 陳小姐</p>
            <div class="logo02"></div>
          </div>
        </div>
      </div>
    </footer>
    </div>
</template>

<style scoped>
    @import './css/inner.css';
    .datahight {
        margin: 15px;
    }
</style>

<script>
import importJQ from './js/main'
import { mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import downloadCsv from 'download-csv'
import cookies from 'vue-cookies'
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
export default {
  components: { DatePicker, Loading },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      updateTime: DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      yearDate: DateTime.local().toFormat('yyyy'),
      endDate: DateTime.local().toFormat('yyyy-MM-dd'),
      reactHistory: '',
      downLoadArrayColumns: '',
      downLoadArrayData: [],
      meterMin: '',
      meterHour: ''
    }
  },
  methods: {
    ...mapMutations([
      'COUNTING_TIMER',
      'LOGIN_OUT'
    ]),
    disabledDate (date) {
      const today = new Date(this.beginDate)
      today.setHours(0, 0, 0, 0)
      return date < today || date > new Date(today.getTime() + 10 * 24 * 3600 * 1000)
    },
    disabledMonth (date) {
      const today = new Date(this.beginDate)
      today.setHours(0, 0, 0, 0)
      return date < today || date > new Date(today.getTime() + 300 * 24 * 3600 * 1000)
    },
    clickDownloadCsv () {
      downloadCsv(this.downLoadArrayData, this.downLoadArrayColumns, '下載.csv')
      this.isLoading = false
    },
    async getReactHistory () {
      this.isLoading = true
      const token = cookies.get('Authorization')
      const reactHistory = await axios.post('/api/cems/getReactHistory',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (reactHistory.data.success) {
        this.reactHistory = reactHistory.data
      }
      this.reactHistory.data = this.reactHistory.data.sort((a, b) => {
        if (a.execDate > b.execDate) return 1
        if (a.execDate < b.execDate) return -1
        return 0
      })
      this.reactHistoryDownload()
    },
    async getEssData () {
      this.isLoading = true
      const token = cookies.get('Authorization')
      const downLoadEssData = await axios.post('/api/cems/downLoadEssData',
        {
          date: this.beginDate
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (downLoadEssData.data.success) {
        this.essDownload(downLoadEssData.data.data)
        this.isLoading = false
      }
    },
    async getMeterMin (str) {
      this.isLoading = true
      const token = cookies.get('Authorization')
      const MeterMin = await axios.post('/api/cems/downloadMeterMin',
        {
          meterType: str,
          dateStart: this.beginDate,
          dateEnd: this.endDate
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (MeterMin.data.success && str === 'high') {
        this.meterMin = MeterMin.data
        this.highMeterMinDownload()
      } else if (MeterMin.data.success && str === 'low') {
        this.meterMin = MeterMin.data
        this.lowMeterMinDownload()
      }
    },
    async getMeterHour () {
      this.isLoading = true
      const token = cookies.get('Authorization')
      const MeterHour = await axios.post('/api/cems/downloadMeterHour',
        {
          dateStart: this.beginDate,
          dateEnd: this.endDate
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (MeterHour.data.success) {
        this.meterHour = MeterHour.data
        this.meterHourDownload()
      }
    },
    async getEsaving () {
      // this.isLoading = true
      const token = cookies.get('Authorization')
      const eSaving = await axios.post('/api/cems/downlaodESaving',
        {
          queryYear: this.yearDate
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (eSaving.data.success) {
        this.eSaving = eSaving.data
        this.eSavingDownLoad()
      }
    },
    eSavingDownLoad () {
      console.log(this.eSaving.data)
      this.downLoadArrayData = []
      this.downLoadArrayColumns = {
        ems: '單位名稱',
        timestamp: '時間',
        publicSavingRate: '節電率(%)'
      }
      for (let i = 0; i < this.eSaving.data.length; i++) {
        const obj = {
          ems: this.eSaving.data[i].ems,
          timestamp: this.eSaving.data[i].month + '月',
          publicSavingRate: this.eSaving.data[i].data.publicSavingRate
        }
        this.downLoadArrayData.push(obj)
      }
      this.clickDownloadCsv()
    },
    highMeterMinDownload () {
      this.downLoadArrayData = []
      this.downLoadArrayColumns = {
        date: '執行時間',
        ems: '單位名稱',
        timestamp: '時間',
        kw: '即時用電需量(kW)',
        demand: '用電需量占比'
      }
      for (let i = 0; i < this.meterMin.data.length; i++) {
        for (let j = 0; j < this.meterMin.data[i].resources.length; j++) {
          let obj = {}
          if (j === 0) {
            obj = {
              date: this.meterMin.data[i].date,
              ems: this.meterMin.data[i].ems
            }
          }
          obj.timestamp = this.meterMin.data[i].resources[j].timestamp
          obj.kw = this.meterMin.data[i].resources[j].kw
          obj.demand = this.meterMin.data[i].resources[j]['demand_%']
          this.downLoadArrayData.push(obj)
        }
      }
      console.log(this.downLoadArrayData)
      this.clickDownloadCsv()
    },
    lowMeterMinDownload () {
      this.downLoadArrayData = []
      this.downLoadArrayColumns = {
        date: '執行時間',
        ems: '單位名稱',
        timestamp: '時間',
        kWh: 'kWh',
        customerNumber: '樓層'
      }
      for (let i = 0; i < this.meterMin.data.length; i++) {
        for (let j = 0; j < this.meterMin.data[i].resources.length; j++) {
          for (let k = 0; k < this.meterMin.data[i].resources[j].length; k++) {
            for (let x = 0; x < this.meterMin.data[i].resources[j][k].length; x++) {
              const obj = {}
              if (x === 0 && j === 0 && k === 0) {
                obj.date = this.meterMin.data[i].date
                obj.ems = this.meterMin.data[i].ems
              }
              obj.customerNumber = this.switchFloor(this.meterMin.data[i].resources[j][k][x].customerNumber)
              obj.timestamp = this.meterMin.data[i].resources[j][k][x].timestamp
              obj.kWh = this.meterMin.data[i].resources[j][k][x].kWh
              this.downLoadArrayData.push(obj)
            }
          }
        }
      }
      this.clickDownloadCsv()
    },
    switchFloor (str) {
      if (str === '17580091') return 'METER_9F'
      if (str === '17580071') return 'METER_10F'
      if (str === '17580070') return 'METER_7F'
      if (str === '17580004') return 'METER_B1B2'
      if (str === '17580081') return 'METER_8F'
    },
    reactHistoryDownload () {
      this.downLoadArrayData = []
      this.downLoadArrayColumns = {
        execDate: '執行時間',
        execTimesStart: '執行開始',
        execTimesEnd: '執行結束',
        totalRealReduceCap: '實際抑低容量',
        execRate: '執行率',
        cashBack: '回饋金額',
        ems: '單位名稱',
        contribution: '貢獻度',
        predictReduceCap: '預估抑低量',
        realReduceCap: '實際抑低量',
        remark: '備註'
      }
      for (let i = 0; i < this.reactHistory.data.length; i++) {
        for (let j = 0; j < this.reactHistory.data[i].emsDetail.length; j++) {
          let obj = {}
          if (j === 0) {
            obj = {
              execDate: this.reactHistory.data[i].execDate,
              execTimesStart: this.reactHistory.data[i].execTimesStart,
              execTimesEnd: this.reactHistory.data[i].execTimesEnd,
              totalRealReduceCap: this.reactHistory.data[i].totalRealReduceCap,
              execRate: this.reactHistory.data[i].execRate * 100,
              cashBack: this.reactHistory.data[i].cashBack
            }
          }
          obj.ems = this.reactHistory.data[i].emsDetail[j].ems
          obj.contribution = this.reactHistory.data[i].emsDetail[j].contribution
          obj.predictReduceCap = this.reactHistory.data[i].emsDetail[j].predictReduceCap
          obj.realReduceCap = this.reactHistory.data[i].emsDetail[j].realReduceCap
          obj.remark = this.reactHistory.data[i].emsDetail[j].remark
          this.downLoadArrayData.push(obj)
        }
      }
      this.clickDownloadCsv()
    },
    meterHourDownload () {
      this.downLoadArrayData = []
      this.downLoadArrayColumns = {
        date: '時間',
        ems: '單位名稱',
        hour: '小時',
        pv: '小時累積發電量',
        min: '分鐘',
        CO2: '累積減碳量',
        kwh: '分鐘累積發電量'
      }
      for (let i = 0; i < this.meterHour.data.length; i++) {
        if (this.meterHour.data[i].hourData && this.meterHour.data[i].minData) {
          for (let j = 0; j < this.meterHour.data[i].minData.length; j++) {
            const obj = {}
            if (j === 0) {
              obj.date = this.meterHour.data[i].date
              obj.ems = this.meterHour.data[i].ems.hourName
            }
            if (j < this.meterHour.data[i].hourData.length) {
              obj.hour = this.meterHour.data[i].hourData[j].hhmm
              obj.pv = this.meterHour.data[i].hourData[j].pv
            }
            obj.min = this.meterHour.data[i].minData[j].timestamp
            obj.CO2 = this.meterHour.data[i].minData[j].CO2
            obj.kwh = this.meterHour.data[i].minData[j].kwh
            this.downLoadArrayData.push(obj)
          }
        }
      }
      this.clickDownloadCsv()
    },
    essDownload (essData) {
      this.downLoadArrayData = []
      this.downLoadArrayColumns = {
        time: '時間',
        ems: '單位名稱',
        soc: '電池剩餘容量(%)',
        power: '今日充放電功率(kW)'
      }
      for (let i = 0; i < essData.length; i++) {
        for (let j = 0; j < essData[i].data.length; j++) {
          const obj = {}
          if (j === 0) {
            obj.ems = essData[i].ems
          }
          obj.time = essData[i].data[j].time
          obj.power = essData[i].data[j].power
          obj.soc = essData[i].data[j].soc
          this.downLoadArrayData.push(obj)
        }
      }
      this.clickDownloadCsv()
    }
  },
  mounted () {
    importJQ()
  },
  computed: {

  }
}
</script>
